<template>
  <BasicButton
    :large="large"
    :small="small"
    :mini="mini"
    :stopPropagation="stopPropagation"
    :disabled="disabled"
    @click="e => handleClick()"
  >
    <template v-if="!disabled">
      <span
        class="text--w-demi"
        :class="{ 'ecom-button__price-cut': getHasPriceAdjustment }"
        >{{ formatPrice(getDefaultPrice) }}&nbsp;</span
      >
      <span v-if="getHasPriceAdjustment" class="text--clr-purple text--w-demi"
        >&nbsp;{{ formatPrice(getAdjustedPrice) }}&nbsp;</span
      >
      <span>-&nbsp;</span>
    </template>
    <span class="ecom-button__cta-block text--w-demi">
      <slot>{{ UI_ELEMENT("cta_add_to_cart") }} </slot>
    </span>
    <div
      class="ecom-button__confirm flex--col-center-center"
      :class="{
        'ecom-button__confirm--active': isClicked && alertActive && !noConfirm
      }"
    >
      <span
        class="ecom-button__confirm-text text--clr-black"
        :class="[
          `text-commerce-button-primary${small ? '-small' : ''}`,
          {
            'ecom-button__confirm-text--active':
              isClicked && alertActive && !noConfirm
          }
        ]"
      >
        {{
          small
            ? UI_ELEMENT("ecom_confirm_add_to_cart_mini")
            : UI_ELEMENT("ecom_confirm_add_to_cart")
        }}
      </span>
    </div>
  </BasicButton>
</template>
<script>
import { mapGetters, mapState } from "vuex"

export default {
  name: "EcomButton",

  props: {
    price: Object,
    compareAtPrice: Object,
    adjustmentValue: Number,
    large: Boolean,
    small: Boolean,
    mini: Boolean,
    stopPropagation: Boolean,
    disabled: Boolean,
    noConfirm: Boolean
  },

  data: () => ({
    isClicked: false
  }),

  methods: {
    handleClick(evt) {
      this.isClicked = true
      this.$emit("click", evt)
    }
  },

  computed: {
    ...mapState("cart", ["alertActive"]),
    ...mapGetters("locale", ["formatPrice"]),
    ...mapGetters("content", [
      "GET_SITE_CONFIG",
      "getHasGlobalPriceAdjustment"
    ]),

    getHasPriceAdjustment() {
      return this.getDefaultPrice.cents > this.getAdjustedPrice.cents
    },

    getDefaultPrice() {
      if (this.compareAtPrice && this.compareAtPrice.cents > 0) {
        return this.compareAtPrice
      } else {
        return this.price
      }
    },

    getAdjustedPrice() {
      const _adjVal =
        (this.getHasGlobalPriceAdjustment &&
          this.GET_SITE_CONFIG.saleAdjustmentValue) ||
        this.adjustmentValue
      // The globally-applied adjustment, if it exists, takes precedence over any other adjustment value (altho ideally there should not be more than one)
      return _adjVal
        ? {
            ...this.price,
            cents: (this.price.cents * (100 - _adjVal)) / 100
          }
        : this.price
    }
  },

  watch: {
    alertActive(newVal) {
      if (!newVal && this.isClicked) this.isClicked = false
    }
  }
}
</script>
<style lang="scss">
.ecom-button {
  &__price-cut {
    position: relative;
    // text-decoration: line-through;
    color: getcolour(bleach_white);
    &:after {
      content: "";
      position: absolute;
      width: 1.5px;
      height: 150%;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transform: translate(0, -50%) rotate(45deg);
      background-color: getcolour(bleach_white);
    }
  }
  &__cta-block {
    display: inline-block;
  }

  &__confirm {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: getcolour(bleach_lime);
    opacity: 0;
    transition: opacity 0.2s linear 0.1s;

    &--active {
      transition: opacity 0.2s linear;
      opacity: 1;
    }
  }

  &__confirm-text {
    display: inline-block;
    opacity: 0;
    transition: opacity 0.1s linear;

    &--active {
      transition: opacity 0.1s linear 0.2s;
      opacity: 1;
    }
  }
}
</style>
